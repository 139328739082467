'use client'

import React from 'react'
import { Inter } from 'next/font/google'
import { SessionProvider } from 'next-auth/react'

import { ThemeProviders } from './themeProviders'
import '../styles/globals.css'

const inter = Inter({ subsets: ['latin'] })

interface RootLayoutProps {
  children: React.ReactNode
  params: { session: any }
}

function RootLayout({ children, params: { session }}: RootLayoutProps) {
  return (
    <html lang="en">
      <body className={inter.className}>
        <SessionProvider session={session}>
          <ThemeProviders>
            {children}
          </ThemeProviders>
        </SessionProvider>
      </body>
    </html>
  )
}

export default RootLayout
