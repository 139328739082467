import { NextUIProvider } from '@nextui-org/react'
import { ThemeProvider as NextThemesProvider } from 'next-themes'

export function ThemeProviders({children}: { children: React.ReactNode }) {
	return (
		<NextThemesProvider defaultTheme="light" attribute="class">
			<NextUIProvider>
				{children}
			</NextUIProvider>
		</NextThemesProvider>
	)
}